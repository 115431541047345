import React from 'react';

const Spinner = () => {
    return (
        <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-50"></div>
        </div>
    );
};

export default Spinner;
